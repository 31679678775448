import Highway from '@dogstudio/highway'

export default class PageRenderer extends Highway.Renderer {
    onEnter() {
        document.body.dataset.type = 'page'

        // Change to default video
        window.emitter.emit('setVideo', false)
    }
    onLeave() {}
    onEnterCompleted() {
        // // Form labels
        // const forms = this.wrap.querySelectorAll('.m-form')
        // for (let i = 0; i < forms.length; i++) {
        //     new Formino(forms[i])        
        // }
    }
    onLeaveCompleted() {}
}