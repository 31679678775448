import { Mesh, Sprite, SpriteMaterial, Math as ThreeMath, Spherical } from 'three'

import { TweenLite } from 'gsap'

export default class Landmark {
    constructor(props = {}) {
        // Props
        this.radius = props.radius || 0
        this.phi = props.phi || 90
        this.theta = props.theta || 0
        this.texture = props.texture || false
        this.textureHover = props.textureHover || false
        this.id = props.id || false
        this.width = props.width || 22

        // Generate mesh
        this.generate()
    }

    generate() {
        // Mesh
        this.mesh = new Mesh()
        this.mesh.position.setFromSpherical(new Spherical(this.radius - 20, ThreeMath.degToRad(this.phi), ThreeMath.degToRad(this.theta)))
        this.mesh.width = this.width

        // Material
        this.material = new SpriteMaterial({ 
            map: this.texture
        })

        // Sprite
        this.sprite = new Sprite(this.material)
        this.mesh.add(this.sprite)

        // Material Hover
        this.materialHover = new SpriteMaterial({ 
            map: this.textureHover
        })

        // Sprite Hover
        this.spriteHover = new Sprite(this.materialHover)
        this.spriteHover.scale.x = 0.0001
        this.spriteHover.scale.y = 0.0001
        this.mesh.add(this.spriteHover)
    }

    mouseOver() {
        TweenLite.to(this.spriteHover.scale, 0.4, { x: 1.2, y: 1.2, ease: Back.easeOut.config(1.7) })
    }

    mouseOut() {
        TweenLite.to(this.spriteHover.scale, 0.4, { x: 0.0001, y: 0.0001 })
    }
}