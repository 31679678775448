import Highway from '@dogstudio/highway'

import Swiper from 'swiper'

export default class RoomRenderer extends Highway.Renderer {
    onEnter() {
        document.body.dataset.type = 'room'

        // Change video
        window.emitter.emit('setVideo', this.properties.view.dataset.id)
    }
    onLeave() {}
    onEnterCompleted() {
        // Iterate details
        const details = this.wrap.querySelectorAll('.o-rooms__detail')
        if(details) {
            details.forEach(detail => {
                window.emitter.emit('addDetail', {
                    id: detail.dataset.id,
                    phi: detail.dataset.phi,
                    theta: detail.dataset.theta
                })
            })
        }

        // Go Back links
        const back = this.wrap.querySelector('.o-rooms__back')
        if(back) {
            back.addEventListener('click', () => {
                document.body.classList.remove('o-body--main-on')
                window.emitter.emit('mainBack')
            })
        }
        const mask = this.wrap.querySelector('.o-main__mask')
        if(mask) {
            mask.addEventListener('click', () => {
                document.body.classList.remove('o-body--main-on')
                window.emitter.emit('mainBack')
            })
        }

        // Images slider
        this.imagesSlider = new Swiper(this.wrap.querySelector('.o-rooms__sliders'), {
            speed: 800,
            allowTouchMove: false,
            simulateTouch: false,
            // loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
        })
        // Inside image sliders
        const sliders = this.wrap.querySelectorAll('.o-rooms__images')
        sliders.forEach(slider => {
            if(slider.querySelector('.swiper-slide')) {
                new Swiper(slider, {
                    slidesPerView: 'auto',
                    speed: 400,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                })
            } else {
                slider.classList.add('o-rooms__images--nope')
            }
        })
        // Details slider
        this.detailsSlider = new Swiper(this.wrap.querySelector('.o-rooms__details'), {
            speed: 800,
            // spaceBetween: 30,
            autoHeight: true,
            allowTouchMove: false,
            simulateTouch: false,
            threshold: 100,
            // loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            controller: {
                control: this.imagesSlider
            } 
        })

        // Details slider change
        this.detailsSlider.on('slideChange', () => {
            if(document.querySelector('.o-rooms__arrow--disabled'))
                document.querySelector('.o-rooms__arrow--disabled').classList.remove('o-rooms__arrow--disabled')
            if(this.detailsSlider.realIndex == 0) {
                document.querySelector('.o-rooms__arrow--previous').classList.add('o-rooms__arrow--disabled')
            } else if(this.detailsSlider.realIndex >= this.detailsSlider.slides.length - 1) {
                document.querySelector('.o-rooms__arrow--next').classList.add('o-rooms__arrow--disabled')
            }
        })

        // Swiper arrows
        this.wrap.querySelector('.o-rooms__arrow--next').addEventListener('click', () => {
            this.detailsSlider.slideNext()
        })
        this.wrap.querySelector('.o-rooms__arrow--previous').addEventListener('click', () => {
            this.detailsSlider.slidePrev()
        })
    }
    onLeaveCompleted() {
        // Destroy swiper
        
        // Remove details
        window.emitter.emit('cleanDetails')
    }
}