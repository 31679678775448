import Highway from '@dogstudio/highway'
import { TimelineMax, TweenLite, Power0, Power2 } from 'gsap'

const mask = document.querySelector('.o-body__mask')
const loaderBar = mask.querySelector('.o-body__loader-bar')

export default class DefaultTransition extends Highway.Transition {
    in({ from, to, done }) {
        // Remove old view
        from.remove()

        // Remove menu
        if(document.body.classList.contains('o-body--menu-on')) {
            document.querySelector('.o-menu').style.display = 'none'
            document.body.classList.remove('o-body--menu-on')
        }

        // Remove main
        if(document.body.classList.contains('o-body--main-on')) {
            document.querySelector('.o-main').style.display = 'none'
            document.body.classList.remove('o-body--main-on')
        }

        // Save done
        this.done = done

        // Fill loader
        this.loaderTimeline = new TimelineMax()
        this.loaderTimeline.add(TweenLite.to(loaderBar, 1.8, { 'clip-path': 'polygon(0% 33%, 100% 33%, 100% 100%, 0% 100%)', ease: Power2.easeInOut }))

        // Check video loaded
        this.videoLoad = setInterval(this.checkLoad.bind(this), 500)
    }
    out({ done }) {
        // Masking
        mask.style.pointerEvents = 'auto'
        TweenLite.to(mask, 0.8, { opacity: 1, ease: Power0.easeNone, onComplete: () => {
            // Scroll top
            window.scrollTo(0, 0)
            done()
        } })
        // Loading start
        TweenLite.set(loaderBar, { 'clip-path': 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)' })
        TweenLite.to(loaderBar, 0.8, { 'clip-path': 'polygon(0% 65%, 100% 65%, 100% 100%, 0% 100%)', ease: Power2.easeInOut })
    }
    checkLoad() {
        if(this.wrap.querySelector('.o-main__content').classList.contains('o-main__content--video-ready')) {
            clearInterval(this.videoLoad)
            document.querySelector('.o-menu').style.display = 'block'
            document.querySelector('.o-main').style.display = 'block'
            // Add loading end step
            this.loaderTimeline.add( TweenLite.to(loaderBar, 0.8, { 'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', ease: Power2.easeInOut }), '+=0.2' )
            // Loader mask disappear
            this.loaderTimeline.add( TweenLite.to(mask, 0.8, {
                opacity: 0,
                ease: Power0.easeNone,
                onComplete: () => {
                    mask.style.pointerEvents = 'none'
                    // Reset loader
                    TweenLite.set(loaderBar, { 'clip-path': 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)' })
                    this.done()
                }
            }), '+=0.2' )
        }
    }
}