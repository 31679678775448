import { TextureLoader } from 'three'

export default class Loader {
    constructor() {
        this.texturesToLoad = []
        this.textures = {}
    }

    add(texture) {
        if(!this.texturesToLoad[texture])
            this.texturesToLoad.push(texture)
    }

    load(textureToLoad) {
        this.textures[textureToLoad] = new TextureLoader().load(textureToLoad, texture => {
            console.log('loaded', texture)
        })
    }

    loadAll() {
        const texturesLength = this.texturesToLoad.length
        let count = 0
        this.texturesToLoad.map(textureToLoad => {
            this.textures[textureToLoad] = new TextureLoader().load(
                textureToLoad, 
                texture => {
                    count ++
                    if(count >= texturesLength) {
                        window.emitter.emit('loadedAll')
                    }
                },
                undefined,
                err => {
                    count ++
                    if(count >= texturesLength) {
                        window.emitter.emit('loadedAll')
                    }
                }
            )
        })
        // console.log(this.textures)
    }

}