import MobileDetect from 'mobile-detect'

import { Vector2, Raycaster } from 'three'

export default class Raycast {
  constructor(container, camera) {
    this.watched = []
    this.container = container
    this.camera = camera
    this.raycaster = new Raycaster()
    this.mouse = false
    this.mouseover = false

    // Mobile detect
    const md = new MobileDetect(window.navigator.userAgent)
    this.isTouch = md.mobile() ? true : false

    // Binding this
    this.onClick = this.onClick.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
    this.onMove = this.onMove.bind(this)
  }

  onMove(event) {
    this.mouse = new Vector2()
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1
    this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1
    this.raycaster.setFromCamera(this.mouse, this.camera) 

    // Detect mouseover
    let intersects = this.raycaster.intersectObjects(this.watched)
    if(intersects.length > 0 && !this.mouseover) {
      window.emitter.emit('spriteOver', intersects[0].object)
      this.mouseover = intersects[0].object
    }
    // Detect mouseout
    if(this.mouseover && intersects.length == 0) {
      window.emitter.emit('spriteOut', this.mouseover)
      this.mouseover = false
    }
  }

  onClick(event) {
    this.mouse = new Vector2()
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1
    this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1
    this.raycaster.setFromCamera(this.mouse, this.camera)
    // Detect click
    let intersects = this.raycaster.intersectObjects(this.watched)
    if(intersects.length > 0) {
      window.emitter.emit('spriteClick', intersects[0].object)
    }
  }

  onTouchEnd(event) {
    this.mouse = new Vector2()
    this.mouse.x = (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1
    this.mouse.y = - (event.changedTouches[0].clientY / window.innerHeight) * 2 + 1
    this.raycaster.setFromCamera(this.mouse, this.camera)
    // Detect touch
    let intersects = this.raycaster.intersectObjects(this.watched)
    if(intersects.length > 0) {
      window.emitter.emit('spriteClick', intersects[0].object)
    }
  }

  start() {
    if(this.isTouch) {
      this.container.addEventListener('touchend', this.onTouchEnd, false)
    } else {
      this.container.addEventListener('click', this.onClick, false)
      this.container.addEventListener('mousemove', this.onMove, false)
    }
  }

  stop() {
    document.removeEventListener('mousemove', this.onMove)
    this.mouseover = false
    if(this.isTouch) {
      this.container.removeEventListener('touchend', this.onTouchEnd, false)
    } else {
      this.container.removeEventListener('click', this.onClick)
    }
  }

  add(object) {
    this.watched.push(object)
  }

  clear() {
    this.watched = []
  }
}