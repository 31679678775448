import { PerspectiveCamera, Vector3, Scene, SphereGeometry, VideoTexture, TextureLoader, MeshBasicMaterial, Mesh, LinearFilter, WebGLRenderer, Math as ThreeMath, Spherical } from 'three'

import Ola from '../libs/Ola'
import MobileDetect from 'mobile-detect'
import { TweenLite, Back } from 'gsap'

// TODO :
// Unzoom and go to initial position when Update video
// Set interactive or not
// Gyroscope
// Resize landmark on resize
// Clean block Y
// Be able to set initial position
// Do something when video loading is too long ?

export default class ThreeSixZero {
    constructor(props = {}) {
        // Props
        this.container = props.container || document.body
        // this.videoSrc = props.src || false
        this.fov = props.fov || 95 // Less fov : less disformation // More fov :  more quality
        this.fovDelta = props.fovDelta || 15
        this.noVideo = props.noVideo || false
        this.latDelta = 70
        this.fovMax = this.fov
        this.fovMin = this.fov - this.fovDelta
        this.ola = Ola({ fov: this.fov }, 75)
        this.translate = Ola({ lon: 0, lat: 0 }, 150)
        this.isUserInteracting = false
        this.pointer = {}
        this.phi = 0
        this.theta = 0
        this.radius = props.radius || 200
        this.landmarks = []

        // Create video
        if(!this.noVideo) {
            this.video = this.container.querySelector('video')
            this.video.width = 640
            this.video.height = 360
            this.video.pause()
        }

        // Mobile detect
        const md = new MobileDetect(window.navigator.userAgent)
        this.isTouch = md.mobile() ? true : false

        // Binding
        this.checkLoad = this.checkLoad.bind(this)

        // Init
        this.init()
    }

    init() {
        // Create camera
        this.camera = new PerspectiveCamera(this.fov, this.container.offsetWidth / this.container.offsetHeight, 1, this.radius)
        this.camera.target = new Vector3(0, 0, 0)

        // Create scene
        this.scene = new Scene()
        this.geometry = new SphereGeometry(this.radius, 32, 32)
        this.geometry.scale(-1, 1, 1)

        // Create texture
        if(!this.noVideo) {
            this.texture = new VideoTexture(this.video)
            this.texture.minFilter = LinearFilter
        } else {
            this.texture = new TextureLoader().load('/sites/default/themes/custom/sdt/videos/video-1.png')
        }

        // // Create png texturee
        // this.texture = new TextureLoader().load('/sites/default/themes/custom/sdt/videos/video-1.png')

        this.material = new MeshBasicMaterial({ map: this.texture })
        this.mesh = new Mesh(this.geometry, this.material)
        this.scene.add(this.mesh)

        // Create renderer
        this.renderer = new WebGLRenderer()
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight)
        this.container.appendChild(this.renderer.domElement)

        // Animate
        this.animate()

        // Interactive
        if(!this.isTouch)
            this.activateZoom()
        this.activateMovement()
    }

    setVideo(src) {
        // Create video
        if(!this.noVideo) {
            this.video.src = src
        } else {
            this.texture = new TextureLoader().load(src)
            this.material.map = this.texture
        }

        // Wait for video load
        this.videoLoad = setInterval(this.checkLoad, 500)
    }

    checkLoad() {
        if(!this.noVideo) {
            if(this.video.readyState >= 3){
                window.emitter.emit('videoReady')
                this.video.play()
                clearInterval(this.videoLoad)
            }
        } else {
            window.emitter.emit('videoReady')
            clearInterval(this.videoLoad)
        }
    }

    activateZoom() {
        this.container.addEventListener('mousewheel', this.onMouseWheel.bind(this), false)
    }

    activateMovement() {
        if(this.isTouch) {
            this.container.addEventListener( 'touchstart', this.onMouseDown.bind(this), false )
            this.container.addEventListener( 'touchmove', this.onMouseMove.bind(this), false )
            this.container.addEventListener( 'touchend', this.onMouseUp.bind(this), false )
        } else {
            this.container.addEventListener( 'mousedown', this.onMouseDown.bind(this), false )
            this.container.addEventListener( 'mousemove', this.onMouseMove.bind(this), false )
            this.container.addEventListener( 'mouseup', this.onMouseUp.bind(this), false )
        }
    }

    onMouseDown(event) {
        // Check touch
        let clientX, clientY
        if(this.isTouch) {
            clientX = event.touches[0].clientX
            clientY = event.touches[0].clientY
        } else {
            clientX = event.clientX
            clientY = event.clientY
        }

        // Touch start
        this.isUserInteracting = true
        this.pointer.x = clientX
        this.pointer.y = clientY
        this.pointer.lon = this.translate.lon
        this.pointer.lat = this.translate.lat
    }

    onMouseMove(event) {
        // Check touch
        let clientX, clientY
        if(this.isTouch) {
            clientX = event.touches[0].clientX
            clientY = event.touches[0].clientY
        } else {
            clientX = event.clientX
            clientY = event.clientY
        }

        // Translate view
        if (this.isUserInteracting) {
            this.translate.lon = (this.pointer.x - clientX) * 0.2 + this.pointer.lon
            const lat = (clientY - this.pointer.y) * 0.2 + this.pointer.lat

            if(lat <= this.latDelta / 2 && lat >= this.latDelta / -2)
                this.translate.lat = (clientY - this.pointer.y) * 0.2 + this.pointer.lat
        }
    }

    onMouseUp(event) {
        this.isUserInteracting = false
    }

    onMouseWheel(event) {
        // Handle zoom
        const newFov = this.camera.fov - event.wheelDeltaY * 0.05
        if(newFov >= this.fovMin && newFov <= this.fovMax) {
            this.ola.set({ fov: this.camera.fov - event.wheelDeltaY * 0.05 })
        } else if(newFov < this.fovMin) {
            this.ola.set({ fov: this.fovMin })
        } else if(newFov > this.fovMax) {
            this.ola.set({ fov: this.fovMax })
        }
    }

    animate() {
        this.update()
        requestAnimationFrame(this.animate.bind(this))
    }

    update() {
        // Render scene
        this.renderer.render(this.scene, this.camera)

        // Movement
        this.lat = Math.max(this.latDelta / -2, Math.min(this.latDelta / 2, this.translate.lat))
        this.phi = ThreeMath.degToRad(90 - this.translate.lat)
        this.theta = ThreeMath.degToRad(this.translate.lon)
        this.camera.target.x = 500 * Math.sin(this.phi) * Math.cos(this.theta)
        this.camera.target.y = 500 * Math.cos(this.phi)
        this.camera.target.z = 500 * Math.sin(this.phi) * Math.sin(this.theta)
        this.camera.lookAt(this.camera.target)

        // Update ola fov
        this.camera.fov = this.ola.fov
        this.camera.updateProjectionMatrix()
    }

    pause() {
        this.video.pause()
    }

    play() {
        this.video.play()
    }

    addLandmark(landmark) {
        // initial scale to pop animation
        landmark.mesh.scale.x = 0
        landmark.mesh.scale.y = 0

        this.scene.add(landmark.mesh)
        this.landmarks.push(landmark.mesh)

        // Animate landmark
        TweenLite.to(landmark.mesh.scale, 0.6, { x: landmark.width, y: landmark.width, ease: Back.easeOut.config(1.7) })
    }

    cleanLandmarks() {
        this.landmarks.forEach(landmark => {
            this.scene.remove(landmark)
        })
    }

    hideLandmarks() {
        this.landmarks.forEach(landmark => {
            TweenLite.to(landmark.scale, 0.6, { x: 0.0001, y: 0.0001, onComplete: () => {
                landmark.children[1].scale.x = 0
                landmark.children[1].scale.y = 0
            } })
        })
    }

    showLandmarks() {
        this.landmarks.forEach(landmark => {
            TweenLite.to(landmark.scale, 0.6, { x: landmark.width, y: landmark.width, ease: Back.easeOut.config(1.7) })
        })
    }

    resize() {
        this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight
        this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight)
    }
}