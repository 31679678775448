import '@scss/main.scss'

import './polyfills'
import 'whatwg-fetch'
import 'picturefill'
import 'lazysizes'

import { TimelineMax, TweenLite, Power0, Power2 } from 'gsap'

import Highway from '@dogstudio/highway'
import RoomRenderer from './renderers/RoomRenderer'
import PageRenderer from './renderers/PageRenderer'
import RoomTransition from './transitions/RoomTransition'
import PageTransition from './transitions/PageTransition'

import EventEmitter from 'events'
import Loader from './360/Loader'
import ThreeSixZero from './360/ThreeSixZero'
import Raycast from './360/Raycast'
import Landmark from './360/Landmark'
import Cookizi from './libs/Cookizi'

// TODO : Activate interactive 360 only when tuto is passed
// What happens when you arrive directly on contact or mentions, no tuto ?

document.addEventListener('DOMContentLoaded', function() {
    // Remove fallback message div, it fucks up Highway
    document.querySelector('div[data-drupal-messages-fallback]').remove()

    // Add data router disabled
    document.querySelectorAll('.o-tuto .links a').forEach(link => {
        link.setAttribute('data-router-disabled', true)
    })

    // Highway
    const High = new Highway.Core({
        renderers: {
            room: RoomRenderer,
            page: PageRenderer
        },
        transitions: {
            room: RoomTransition,
            page: PageTransition
        }
    })

    // Highway NAVIGATE IN
    High.on('NAVIGATE_IN', ({ location }) => {
        // Change nav-main active link
        if(document.querySelector('.m-nav-main__link--active-trail'))
            document.querySelector('.m-nav-main__link--active-trail').classList.remove('m-nav-main__link--active-trail')
        if(document.querySelector(`.m-nav-main__link[href="${location.pathname}"]`))
            document.querySelector(`.m-nav-main__link[href="${location.pathname}"]`).classList.add('m-nav-main__link--active-trail')
        // Change nav-secondary active link
        if(document.querySelector('.m-nav-secondary__link.is-active'))
            document.querySelector('.m-nav-secondary__link.is-active').classList.remove('is-active')
        if(document.querySelector(`.m-nav-secondary__link[href="${location.pathname}"]`))
            document.querySelector(`.m-nav-secondary__link[href="${location.pathname}"]`).classList.add('is-active')
    })

    // Highway TRACKING
    High.on('NAVIGATE_END', ({ from, to, location }) => {
        if (typeof gtag !== 'undefined') {
            // eslint-disable-next-line
            gtag('config', window.gtag, {
                'page_path': location.pathname,
                'page_title': to.page.title,
                'page_location': location.href
            })
        }
    })

    // Init event
    window.emitter = new EventEmitter()

    // Global radius
    const radius = 200

    // Init 360
    const threeSixZero = new ThreeSixZero({
        container: document.querySelector('.o-three'),
        radius: radius,
        noVideo: isIE()
    })

    const raycast = new Raycast(document.querySelector('.o-three'), threeSixZero.camera)
    raycast.start()

    // Init loader
    const loader = new Loader()
    loader.add('/sites/default/themes/custom/sdt/dist/img/landmark.png')
    loader.add('/sites/default/themes/custom/sdt/dist/img/landmark-hover.png')

    if(isIE()) {
        loader.add('/sites/default/themes/custom/sdt/videos/video-1.png')
        loader.add('/sites/default/themes/custom/sdt/videos/video-2.png')
        loader.add('/sites/default/themes/custom/sdt/videos/video-7.png')
        loader.add('/sites/default/themes/custom/sdt/videos/video-8.png')
        loader.add('/sites/default/themes/custom/sdt/videos/video-9.png')
    }

    // Load all
    loader.loadAll()

    // Fill loader
    const loaderSVG = document.querySelector('.o-body__loader')
    // const loaderBackground = document.querySelector('#wisepack-background')
    const loaderBar = document.querySelector('.o-body__loader-bar')
    const loaderMask = document.querySelector('.o-body__mask')
    TweenLite.set(loaderBar, { 'clip-path': 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)' })
    const loaderTimeline = new TimelineMax({
        onComplete: () => {
            document.body.classList.remove('o-body--loading')
        }
    })
    loaderTimeline.add(TweenLite.to(loaderSVG, 1.2, { opacity: 1, ease: Power0.easeNone }))
    loaderTimeline.add(TweenLite.to(loaderBar, 1.8, { 'clip-path': 'polygon(0% 33%, 100% 33%, 100% 100%, 0% 100%)', ease: Power2.easeInOut }), '-=0.6')

    // Check video loaded
    const videoLoad = setInterval(checkLoad, 500)
    function checkLoad() {
        if(document.querySelector('.o-main__content').classList.contains('o-main__content--video-ready')) {
            clearInterval(videoLoad)
            endLoading()
        }
    }

    function endLoading() {
        // Add loading end step
        loaderTimeline.add( TweenLite.to(loaderBar, 0.8, { 'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', ease: Power2.easeInOut }), '+=0.2' )
        // Loader mask disappear
        loaderTimeline.add( TweenLite.to(loaderMask, 0.8, { opacity: 0, ease: Power2.easeInOut, onComplete: () => {
            // Reset loader bar
            TweenLite.set(loaderBar, { 'clip-path': 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)' })
        } }), '+=0.2' )
    }

    window.emitter.on('setVideo', id => {
        if(id) {
            if(!isIE()) {
                threeSixZero.setVideo(`/sites/default/themes/custom/sdt/videos/video-${id}.mp4`)
            } else {
                threeSixZero.setVideo(`/sites/default/themes/custom/sdt/videos/video-${id}.png`)
            }
        } else {
            window.emitter.emit('videoReady')
        }
    })

    // Video ready
    window.emitter.on('videoReady', () => {
        document.querySelector('.o-main__content').classList.add('o-main__content--video-ready')
    })

     // Add details
    let landmarks = {}
    window.emitter.on('addDetail', detail => {
        // Landmark size
        let landmarkWidth = 13
        if(document.body.offsetWidth < 1024) {
            landmarkWidth = 20
        } else if (document.body.offsetWidth < 1440) {
            landmarkWidth = 17
        } else if (document.body.offsetWidth < 1680) {
            landmarkWidth = 15
        }

        // Landmark test
        const landmark = new Landmark({
            radius: radius,
            theta: parseInt(detail.theta),
            phi: parseInt(detail.phi),
            id: parseInt(detail.id),
            texture: loader.textures['/sites/default/themes/custom/sdt/dist/img/landmark.png'],
            textureHover: loader.textures['/sites/default/themes/custom/sdt/dist/img/landmark-hover.png'],
            width: landmarkWidth
        })
        threeSixZero.addLandmark(landmark)

        // Add landmark to raycast
        landmark.sprite.landmarkId = parseInt(detail.id)
        raycast.add(landmark.sprite)

        // Add to an landmarks array
        landmarks[detail.id] = landmark
    })

    // Clean details
    window.emitter.on('cleanDetails', () => {
        landmarks = {}
        threeSixZero.cleanLandmarks()
        raycast.watched = []
    })

    // Events
    window.emitter.on('spriteOver', object => {
        landmarks[object.landmarkId].mouseOver()
        document.body.classList.add('o-body--pointer')
    })
    window.emitter.on('spriteOut', object => {
        landmarks[object.landmarkId].mouseOut()
        document.body.classList.remove('o-body--pointer')
    })
    window.emitter.on('spriteClick', object => {
        // Scroll to top
        document.querySelector('.o-main').scrollTop = 0

        // Add main class
        document.body.classList.add('o-body--main-on')

        // Pause video
        threeSixZero.pause()

        // Hide markers
        threeSixZero.hideLandmarks()

        // Fast slide goto
        const slider = High.From.detailsSlider
        slider.slideTo(object.landmarkId, 0)
    })
    window.emitter.on('mainBack', () => {
        // Play video
        threeSixZero.play()

        // Hide markers
        threeSixZero.showLandmarks()
    })

    // Add tuto
    if(document.querySelector('.o-rooms'))
        document.body.classList.add('o-body--tuto')

    // Listen tuto end
    const tuto = document.querySelector('.o-tuto')
    if(tuto) {
        tuto.querySelector('.a-btn--start').addEventListener('click', e => {
            e.preventDefault()
            TweenLite.to(tuto, 0.8, { opacity: 0, ease: Power2.easeInOut, onComplete: () => {
                document.body.classList.remove('o-body--tuto')
                tuto.style.pointerEvents = 'none'
            } })
        })
    }

    // Burger
    document.querySelector('.a-burger').addEventListener('click', () => {
        document.body.classList.toggle('o-body--menu-on')

        // Pause or play video
        if(!isIE()) {
            if(document.body.classList.contains('o-body--menu-on')) {
                threeSixZero.pause()
            } else {
                threeSixZero.play()
            }
        }
    })

    // On resize
    let resizeTimer
    function onResize() {
        clearTimeout(resizeTimer)
        resizeTimer = setTimeout(function() {
            threeSixZero.resize()
        }, 30)
    }
    window.addEventListener('resize', onResize)

    // Cookizi
    const cookizi = new Cookizi({
        types: ['analytics']
    })
    document.querySelector('.m-nav-footer__item:last-child a').addEventListener('click', e => {
        e.preventDefault()
        cookizi.showSidebar()
    })

    // Hide contentinfo on click
    const contentinfo = document.querySelector('.contentinfo')
    if(contentinfo) {
        contentinfo.addEventListener('click', () => {
            contentinfo.classList.add('contentinfo--hidden')
        })
    }

})

function isIE() {
    var ua = window.navigator.userAgent
    var msie = ua.indexOf("MSIE ")
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        return true
    }
    return false
}

// SWPL LOG
console.log('%c S W P L ', 'background: #1467ff; color: white; font-size: 17px; font-weight: bold; line-height: 36px; text-align: center', ' www.swpl.fr');